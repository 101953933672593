<template>
  <div class="engine-variations tile tile--no-padding scroll-horizontal-overflow" v-if="flatEngines.length > 0">
    <div class="engine-variations__header">
      <div class="engine-variations__icon"><img :src="flatEngines[0].icon"/></div>
      <div class="engine-variations__group-name" v-html="renderWithTierAndSize(this.engineGroup.name.replace(` ${flatEngines[0].scale.toUpperCase()}`, ''), flatEngines[0].scale)"></div>
    </div>
    <table class="engine-variations__prop-table">

      <tr>
        <td></td>
        <td class="engine-variations__variant-name engine-variations__border-left engine-variations__border-bottom"
            :colspan="engines[variantName].length"
            v-for="variantName in Object.keys(engines)"
        >{{ variantName }}
        </td>
      </tr>

      <tr v-for="property in properties">
        <td class="engine-variations__legend-label">
          {{ __(property) }}
          <span v-if="property === 'mass'">in kg</span>
        </td>
        <template v-for="variantName in Object.keys(engines)">
          <template v-for="(variant, index) in engines[variantName]">

            <td v-if="property === 'name'" :title="variant.fullName"
                class="engine-variations__variant-prop"
                :class="{'engine-variations__border-left': index === 0}"
            >
              <div v-html="renderWithTierAndSize(`${variant.name.split(' ')[0]} `)"></div>
            </td>

            <td v-else-if="property === 'minConstructSizeCategory'"
                class="engine-variations__variant-prop"
                :class="{'engine-variations__border-left': index === 0}"
            >
              <div class="text--primary text--orbitron">{{variant[property].toUpperCase()}}</div>
            </td>

            <td v-else-if="property === 'tier'"
                :class="{'engine-variations__border-left': index === 0}">
              <div class="skewed-ribbon skewed-ribbon--large">
                <div class="skewed-ribbon__item" :class="getTierRibbonClasses(variant.tier)" :title="'Tier'">
                  {{ variant.tier }}
                </div>
              </div>
            </td>

            <td class="engine-variations__variant-prop"
                :class="getCellClasses(variant, property, index)"
                v-else-if="property === 'craftingTime'">
              {{ displayTime(variant[property]) }}
              <div class="engine-variations__variant-prop-diff" v-html="getDiff(variant, property)"></div>
            </td>

            <td class="engine-variations__variant-prop"
                :class="getCellClasses(variant, property, index)"
                v-else-if="noFractionProperties.includes(property)">
              <div v-html="numberToLocaleString(variant[property], 0, 0)"></div>
              <div class="engine-variations__variant-prop-diff" v-html="getDiff(variant, property)"></div>
            </td>

            <td class="engine-variations__variant-prop"
                :class="getCellClasses(variant, property, index)"
                v-else>
              <div v-html="numberToLocaleString(variant[property], 2, 2)"></div>
              <div class="engine-variations__variant-prop-diff" v-html="getDiff(variant, property)"></div>
            </td>

          </template>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import apiSecured from '../../../api/secured';
import AppHeader from '../../../components/AppHeader';
import { notificationsMixin } from '../../../mixins/notifications';
import { localizationMixin } from '../../../mixins/localization';
import { pageTitleMixin } from "../../../mixins/pageTitle";
import { displayTime, numberToLocaleString, renderWithTierAndSize } from "../../../helpers/itemHelpers";

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, localizationMixin],
  components: {
    AppHeader
  },
  props: ['groupKey'],
  data: () => ({
    loading: false,
    engineGroup: null,
    engines: {},
    flatEngines: [],
    selectedVariant: null,

    properties: [
      'name',
      'tier',
      'minConstructSizeCategory',

      'baseReloadTime',
      'magazineVolume',
      'fittingCapacity',
      'baseDamage',
      'baseCycleTime',
      'baseOptimalAimingCone',
      'falloffAimingCone',
      'baseOptimalTracking',
      'falloffTracking',
      'baseOptimalDistance',
      'falloffDistance',
      'precision',
      'optimalCrossSectionDiameter',
      'baseAccuracy',
      'damageBuff',
      'cycleTimeBuff',
      'optimalAimingConeBuff',
      'optimalTrackingBuff',
      'optimalDistanceBuff',
      'reloadTimeBuff',

      'craftingTime',
      'mass',
      'maxRestoreCount',
      'hitpoints',
      'resistancesAntimatter',
      'resistancesElectromagnetic',
      'resistancesKinetic',
      'resistancesThermic',
      'resistancesAntimatterHp',
      'resistancesElectromagneticHp',
      'resistancesKineticHp',
      'resistancesThermicHp',
    ],

    noFractionProperties: [
      'baseReloadTime',
      'magazineVolume',
      'fittingCapacity',
      'baseCycleTime',
      'precision',
      'optimalCrossSectionDiameter',
      'baseAccuracy',
      'damageBuff',
      'cycleTimeBuff',
      'optimalAimingConeBuff',
      'optimalTrackingBuff',
      'optimalDistanceBuff',
      'reloadTimeBuff',
      'maxRestoreCount',
      'Hitpoints',
      'mass',
      'resistancesAntimatterHp',
      'resistancesElectromagneticHp',
      'resistancesKineticHp',
      'resistancesThermicHp'
    ],
  }),
  methods: {
    renderWithTierAndSize,
    numberToLocaleString,
    displayTime,
    getTierRibbonClasses(tier) {
      return {
        'skewed-ribbon__item--tier-1': tier === 1,
        'skewed-ribbon__item--tier-2': tier === 2,
        'skewed-ribbon__item--tier-3': tier === 3,
        'skewed-ribbon__item--tier-4': tier === 4,
        'skewed-ribbon__item--tier-5': tier === 5,
      }
    },
    getDiff(variant, property) {
      const diffPercent = (variant[property] * 100 / this.selectedVariant[property]) - 100;
      if (diffPercent !== 0) {
        if (diffPercent > 0) {
          return `+${this.numberToLocaleString(diffPercent, 2, 2)} %`;
        }
        return `${this.numberToLocaleString(diffPercent, 2, 2)} %`;
      }
    },
    getCellClasses(engine, property, index) {
      const sorted = this.flatEngines.map(flatEngine => flatEngine[property]).sort((a, b) => a > b ? 1 : -1);
      const min = sorted[0];
      const max = sorted[sorted.length - 1];
      if (min === max) {
        return {
          'engine-variations__border-left': index === 0,
        };
      }
      switch (property) {
        case 'craftingTime':
        case 'mass':
          return {
            'text--success': engine[property] < this.selectedVariant[property] && engine[property] > min,
            'text--warning': engine[property] > this.selectedVariant[property] && engine[property] < max,
            'text--info': engine[property] === min,
            'text--error': engine[property] === max,
            'engine-variations__border-left': index === 0,
          };

        default:
          return {
            'text--success': engine[property] > this.selectedVariant[property] && engine[property] < max,
            'text--warning': engine[property] < this.selectedVariant[property] && engine[property] > min,
            'text--info': engine[property] === max,
            'text--error': engine[property] === min,
            'engine-variations__border-left': index === 0,
          };
      }
    },
    async refreshEngineGroup() {
      this.loading = true;
      await apiSecured.get('/ingame/items/group/by-key/' + this.groupKey).then((res) => {
        this.engineGroup = res.data;
        this.loading = false;
        this.showInfoNotification('Engine group loaded');
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async refreshEngines() {
      this.loading = true;
      const params = {
        groupIds: this.engineGroup.id,
        pageSize: -1,
        recursiveGroupsFilter: true,
      };
      await apiSecured.get('/ingame/items/item', { params }).then((res) => {
        this.resetEngines();
        this.flatEngines = res.data;
        const engines = res.data.sort((a, b) => a.tier > b.tier ? 1 : -1);
        engines.forEach(engine => {
          if (engine.name.includes('Agile')) {
            this.engines.Agile.push(engine);
          }
          else if (engine.name.includes('Defense')) {
            this.engines.Defense.push(engine);
          }
          else if (engine.name.includes('Heavy')) {
            this.engines.Heavy.push(engine);
          }
          else if (engine.name.includes('Precision')) {
            this.engines.Precision.push(engine);
          }
          else {
            this.engines.Basic.push(engine);
            if (this.selectedVariant === null) {
              this.selectedVariant = engine;
            }
          }
        });
        this.loading = false;
        this.showInfoNotification('Engines loaded');
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    async refresh() {
      await this.refreshEngineGroup();
      await this.refreshEngines();
    },
    resetEngines() {
      this.engines = {
        Basic: [],
        Agile: [],
        Defense: [],
        Heavy: [],
        Precision: [],
      }
    }
  },
  created() {
    this.refresh();
  }
}
</script>
