<template>
  <div class="view">
    <app-header :title="`Ingame - ${'itemVariationLibrary'}`"/>
    <div class="main-content-padding">
      <tabs :items="sections" v-model="section"/>

      <airfoil-variations v-if="section === 'Airfoils'"/>

      <template v-if="section === 'Atmospheric Engines'">
        <engine-variations class="mt" :group-key="'AtmosphericEngineXtraSmallGroup'" :key="'AtmosphericEngineXtraSmallGroup'"/>
        <engine-variations class="mt" :group-key="'AtmosphericEngineSmallGroup'" :key="'AtmosphericEngineSmallGroup'"/>
        <engine-variations class="mt" :group-key="'AtmosphericEngineMediumGroup'" :key="'AtmosphericEngineMediumGroup'"/>
        <engine-variations class="mt" :group-key="'AtmosphericEngineLargeGroup'" :key="'AtmosphericEngineLargeGroup'"/>
      </template>

      <template v-if="section === 'Space Engines'">
        <engine-variations class="mt" :group-key="'SpaceEngineXtraSmallGroup'" :key="'SpaceEngineXtraSmallGroup'"/>
        <engine-variations class="mt" :group-key="'SpaceEngineSmallGroup'" :key="'SpaceEngineSmallGroup'"/>
        <engine-variations class="mt" :group-key="'SpaceEngineMediumGroup'" :key="'SpaceEngineMediumGroup'"/>
        <engine-variations class="mt" :group-key="'SpaceEngineLargeGroup'" :key="'SpaceEngineLargeGroup'"/>
        <engine-variations class="mt" :group-key="'SpaceEngineXtraLargeGroup'" :key="'SpaceEngineXtraLargeGroup'"/>
      </template>
      <template v-if="section === 'Space Radars'">
        <radar-variations class="mt" :group-key="'RadarPVPSpaceSmallGroup'" :key="'RadarPVPSpaceSmallGroup'"/>
        <radar-variations class="mt" :group-key="'RadarPVPSpaceMediumGroup'" :key="'RadarPVPSpaceMediumGroup'"/>
        <radar-variations class="mt" :group-key="'RadarPVPSpaceLargeGroup'" :key="'RadarPVPSpaceLargeGroup'"/>
      </template>
      <template v-if="section === 'Cannons'">
        <weapon-variations class="mt" :group-key="'WeaponCannonExtraSmall'" :key="'WeaponCannonExtraSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponCannonSmall'" :key="'WeaponCannonSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponCannonMedium'" :key="'WeaponCannonMedium'"/>
        <weapon-variations class="mt" :group-key="'WeaponCannonLarge'" :key="'WeaponCannonLarge'"/>
      </template>
      <template v-if="section === 'Lasers'">
        <weapon-variations class="mt" :group-key="'WeaponLaserExtraSmall'" :key="'WeaponLaserExtraSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponLaserSmall'" :key="'WeaponLaserSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponLaserMedium'" :key="'WeaponLaserMedium'"/>
        <weapon-variations class="mt" :group-key="'WeaponLaserLarge'" :key="'WeaponLaserLarge'"/>
      </template>
      <template v-if="section === 'Missiles'">
        <weapon-variations class="mt" :group-key="'WeaponMissileExtraSmall'" :key="'WeaponMissileExtraSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponMissileSmall'" :key="'WeaponMissileSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponMissileMedium'" :key="'WeaponMissileMedium'"/>
        <weapon-variations class="mt" :group-key="'WeaponMissileLarge'" :key="'WeaponMissileLarge'"/>
      </template>
      <template v-if="section === 'Railguns'">
        <weapon-variations class="mt" :group-key="'WeaponRailgunExtraSmall'" :key="'WeaponRailgunExtraSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponRailgunSmall'" :key="'WeaponRailgunSmall'"/>
        <weapon-variations class="mt" :group-key="'WeaponRailgunMedium'" :key="'WeaponRailgunMedium'"/>
        <weapon-variations class="mt" :group-key="'WeaponRailgunLarge'" :key="'WeaponRailgunLarge'"/>
      </template>
    </div>
  </div>
</template>

<script>
  import AppHeader from '../../components/AppHeader';
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import EngineVariations from "../../components/Ingame/EnginesLibrary/EngineVariations";
  import AirfoilVariations from "../../components/Ingame/EnginesLibrary/AirfoilVariations";
  import RadarVariations from "../../components/Ingame/EnginesLibrary/RadarVariations";
  import WeaponVariations from "../../components/Ingame/EnginesLibrary/WeaponVariations";

  export default {
    mixins: [pageTitleMixin, localizationMixin],
    components: {
      WeaponVariations,
      RadarVariations,
      AirfoilVariations,
      EngineVariations,
      AppHeader
    },
    watch: {
      section() {
        this.$forceUpdate();
      }
    },
    data: () => ({
      sections: [
        {value: 'Airfoils', title: 'Airfoils', icon: 'https://cdn.hyperion-corporation.de/iconslib/stabilizer_l.png'},
        {value: 'Atmospheric Engines', title: 'Atmospheric Engines', icon: 'https://cdn.hyperion-corporation.de/iconslib/atmosphericenginelarge.png'},
        {value: 'Space Engines', title: 'Space Engines', icon: 'https://cdn.hyperion-corporation.de/iconslib/spaceenginelarge.png'},
        {value: 'Space Radars', title: 'Radars', icon: 'https://cdn.hyperion-corporation.de/iconslib/env_radar-space_001_l_icon.png'},
        {value: 'Cannons', title: 'Cannons', icon: 'https://cdn.hyperion-corporation.de/iconslib/env_cannon-heavy-tower_001_icon.png'},
        {value: 'Lasers', title: 'Lasers', icon: 'https://cdn.hyperion-corporation.de/iconslib/env_cannon-laser-tower_001_icon.png'},
        {value: 'Missiles', title: 'Missiles', icon: 'https://cdn.hyperion-corporation.de/iconslib/env_cannon-missile-launcher_001_icon.png'},
        {value: 'Railguns', title: 'Railguns', icon: 'https://cdn.hyperion-corporation.de/iconslib/env_cannon-railgun_001_icon.png'},
      ],
      section: 'Atmospheric Engines',
    }),
    methods: {

    },
    created() {
      this.setPageTitle(this.__('itemVariationLibrary'));
    }
  }
</script>
