<template>
  <div class="airfoil-variations">
    <h1 class="airfoil-variations__title">Airfoils</h1>
    <div class="scroll-horizontal-overflow">
      <table class="airfoil-variations__table">
        <tr>
          <td></td>
          <td v-for="(engines, size) in classedEngines"
              class="airfoil-variations__border-left"
              :colspan="Object.keys(engines).length">
            <div class="inline-background airfoil-variations__size">{{size.toUpperCase()}}</div>
          </td>
        </tr>
        <tr>
          <td></td>
          <template v-for="(engines, size) in classedEngines">
            <td v-for="(engine, variant) in engines"
                class="airfoil-variations__border-bottom"
                :class="{'airfoil-variations__border-left': isFirstVariant(size, variant)}"
            >
              <div class="inline-background airfoil-variations__variant">
                <img :src="engine.icon"/>
                <div>{{variant}}</div>
              </div>
            </td>
          </template>
        </tr>
        <tr v-for="property in properties">
          <td :class="{'airfoil-variations__border-bottom': ['thetaStall', 'resistancesThermicHp'].includes(property)}">
            <div class="inline-background airfoil-variations__prop-label"
            >{{__(`${property}`)}}</div>
          </td>
          <template v-for="(engines, size) in classedEngines">
            <template v-for="(engine, variant) in engines">
              <td :class="{'airfoil-variations__border-left': isFirstVariant(size, variant), 'airfoil-variations__border-bottom': ['thetaStall', 'resistancesThermicHp'].includes(property)}">
                <div class="inline-background airfoil-variations__prop-value"
                     :class="getCellClasses(engines, engine, property)"
                >
                  <template v-if="property === 'tier'">
                    <div class="skewed-ribbon skewed-ribbon--large">
                      <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">
                        {{ engine.tier }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="['craftingTime'].includes(property)">
                    {{displayTime(engine[property])}}
                  </template>
                  <template v-else-if="['mass'].includes(property)">
                    <span v-html="numberToLocaleString(engine[property], 2, 2)"></span>
                  </template>
                  <template v-else-if="['maxPower','hitpoints','resistancesAntimatterHp','resistancesElectromagneticHp','resistancesKineticHp','resistancesThermicHp'].includes(property)">
                    <span v-html="numberToLocaleString(engine[property], 0, 0)"></span>
                  </template>
                  <template v-else>
                    <span v-html="numberToLocaleString(engine[property], 2, 2)"></span>
                  </template>
                </div>
              </td>
            </template>

          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import AppHeader from '../../../components/AppHeader';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';
  import { pageTitleMixin } from "../../../mixins/pageTitle";
  import { displayTime, numberToLocaleString } from '../../../helpers/itemHelpers';

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin, localizationMixin],
    components: {
      AppHeader
    },
    data: () => ({
      loading: false,
      engineGroup: null,
      engines: [],
      classedEngines: [],

      properties: [
        'tier',
        'maxPower',
        'deltaTheta',
        'maxLiftFactor',
        'liftDragRatio',
        'thetaBeginMaxLift',
        'thetaEndMaxLift',
        'thetaStall',
        'craftingTime',
        'mass',
        'maxRestoreCount',
        'hitpoints',
        'resistancesAntimatter',
        'resistancesElectromagnetic',
        'resistancesKinetic',
        'resistancesThermic',
        'resistancesAntimatterHp',
        'resistancesElectromagneticHp',
        'resistancesKineticHp',
        'resistancesThermicHp',
        'obstructionBufferThreshold',
        'obstructionHorizontalAngle',
        'obstructionVerticalAngle',
        'atmosphereDensityExponent'
      ],
    }),
    methods: {
      numberToLocaleString,
      displayTime,
      getDiff(variants, variant, property) {
        const diffPercent = (variant[property] * 100 / variants.Basic[property]) - 100;
        if (diffPercent !== 0) {
          if (diffPercent > 0) {
            return `+${this.numberToLocaleString(diffPercent, 2, 2)} %`;
          }
          return `${this.numberToLocaleString(diffPercent, 2, 2)} %`;
        }
      },
      getCellClasses(engines, engine, property) {
        const sorted = Object.keys(engines).map(key => engines[key][property]).sort((a, b) => a > b ? 1 : -1);
        const min = sorted[0];
        const max = sorted[sorted.length - 1];
        if (min === max) {
          return {};
        }
        switch (property) {
          case 'craftingTime':
          case 'deltaTheta':
          case 'liftDragRatio':
          case 'fuelRate':
          case 'fuelConsumptionAtMaxThrust':
          case 'mass':
          case 't50':
            return {
              'text--success': engine[property] === min,
              'text--warning': engine[property] === max,
            };

          default:
            return {
              'text--success': engine[property] === max,
              'text--warning': engine[property] === min,
            };
        }
      },
      isFirstVariant(size, variant) {
        return Object.keys(this.classedEngines[size]).findIndex(variantName => variantName === variant) === 0;
      },
      async refreshEngineGroup() {
        this.loading = true;
        await apiSecured.get('/ingame/items/group/by-key/Airfoil').then((res) => {
          this.engineGroup = res.data;
          this.loading = false;
          this.showInfoNotification('Engine group loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshEngines() {
        this.loading = true;
        const params = {
          groupIds: this.engineGroup.id,
          pageSize: -1,
          recursiveGroupsFilter: true,
        };
        await apiSecured.get('/ingame/items/item', { params }).then((res) => {
          const engines = res.data.sort((a, b) => a.name > b.name ? 1 : -1);
          this.classedEngines = {
            'xs': {},
            's': {},
            'm': {},
            'l': {}
          };
          engines.forEach(engine => {
            const size = engine.scale;
            const variant = engine.name;
            this.classedEngines[size][variant] = engine;
          });
          this.loading = false;
          this.showInfoNotification('Engines loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refresh() {
        await this.refreshEngineGroup();
        await this.refreshEngines();

      }
    },
    created() {
      this.refresh();
    }
  }
</script>
